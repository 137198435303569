var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "mb-0 table-card",
      staticStyle: { border: "0" },
      attrs: { "header-tag": "header", "no-body": "" },
      scopedSlots: _vm._u(
        [
          !_vm.noHeader
            ? {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "b-row",
                      { attrs: { "align-v": "center" } },
                      [
                        _vm.title
                          ? _c(
                              "b-col",
                              { attrs: { cols: _vm.hasSearch ? "auto" : "" } },
                              [_c("strong", [_vm._v(_vm._s(_vm.title))])]
                            )
                          : _vm._e(),
                        _vm.hasSearch
                          ? _c("b-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "input-group input-group-flush",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "input-group-prepend" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "input-group-text" },
                                        [
                                          _c("i", {
                                            staticClass: "fe fe-search",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.filter,
                                        expression: "filter",
                                      },
                                    ],
                                    staticClass: "form-control list-search",
                                    attrs: {
                                      type: "search",
                                      placeholder: "Search",
                                    },
                                    domProps: { value: _vm.filter },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.filter = $event.target.value
                                      },
                                    },
                                  }),
                                  _vm.$route.name == "patient-profile"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { href: "javascript:;" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "toggle-show-all"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          !_vm.showAllPrescriptions
                                            ? _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Show All")]
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  "Hide Old Prescriptions"
                                                ),
                                              ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "auto" } },
                          [
                            _c(
                              "b-row",
                              { attrs: { "align-v": "center" } },
                              [
                                _c(
                                  "b-col",
                                  [
                                    _vm.filter
                                      ? [
                                          _vm._v("Showing "),
                                          _c("b", [_vm._v(_vm._s(_vm.rows))]),
                                          _vm.rows == 1 || _vm.rows == 0
                                            ? [_vm._v(" result")]
                                            : [_vm._v(" results")],
                                          _vm._v(
                                            " for “" + _vm._s(_vm.filter) + "”"
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _vm.view == "list"
        ? _c("b-table", {
            ref: "selectableTable",
            class: ["table-nowrap card-table", _vm.className],
            attrs: {
              responsive: "",
              "select-mode": "single",
              selectable: _vm.isSelectable,
              "sticky-header": _vm.scrollable ? _vm.scrollHeight : false,
              fields: _vm.tableHeader,
              items: _vm.tableItem,
              filter: _vm.hasSearch ? _vm.filter : "",
              "current-page": _vm.hasPagination ? _vm.currentPage : "",
              "per-page": _vm.hasPagination ? _vm.itemPerPage : "",
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortDesc = $event
              },
              filtered: _vm.onFiltered,
              "row-selected": _vm.onRowSelected,
            },
            nativeOn: {
              scroll: function ($event) {
                return _vm.onScroll.apply(null, arguments)
              },
            },
            scopedSlots: _vm._u(
              [
                this.$route.name == "home"
                  ? {
                      key: "cell(patientInfo)",
                      fn: function (data) {
                        return [
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              " " + _vm._s(data.item.patientInfo.fullName) + " "
                            ),
                          ]),
                          _c(
                            "small",
                            { staticClass: "text-muted d-inline-block" },
                            [
                              _c(
                                "span",
                                { staticClass: "d-inline-block mr-2" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(data.item.patientInfo.state) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.parseDate(
                                        data.item.patientInfo.birthday,
                                        null,
                                        "/"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    }
                  : null,
                {
                  key: "head(value)",
                  fn: function () {
                    return [
                      _c("b-form-checkbox", {
                        ref: "checkAll",
                        on: {
                          change: function ($event) {
                            return _vm.checkAll()
                          },
                        },
                        model: {
                          value: _vm.selectAll,
                          callback: function ($$v) {
                            _vm.selectAll = $$v
                          },
                          expression: "selectAll",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "cell(value)",
                  fn: function (data) {
                    return [
                      _c("b-form-checkbox", {
                        attrs: { value: data.value },
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      }),
                    ]
                  },
                },
                this.$route.name == "patient-profile" ||
                _vm.$route.name == "treatments"
                  ? {
                      key: "cell(prescriptionstatus)",
                      fn: function (data) {
                        return [
                          _c("b-badge", {
                            class: [
                              "mr-2",
                              data.value == "DRAFTED" ||
                              data.value == "FOR CONFIRMATION"
                                ? "badge-warning"
                                : data.value == "PRESCRIBED" ||
                                  data.value == "CONTINUE" ||
                                  data.value == "COMPLETED" ||
                                  data.value == "REFILLED"
                                ? "badge-success"
                                : data.value == "CANCELLED" ||
                                  data.value == "STOPPED" ||
                                  data.value == "FAILED"
                                ? "badge-danger"
                                : data.value == "SCHEDULED"
                                ? "badge-purple"
                                : "",
                            ],
                            staticStyle: {
                              padding: "5px",
                              display: "inline-block",
                            },
                          }),
                          (data.item.prescriptionstatus == "CANCELLED" &&
                            !!data.item.can_restart) ||
                          data.item.prescriptionstatus == "COMPLETED"
                            ? _c(
                                "span",
                                { staticClass: "tw-capitalize text-secondary" },
                                [_vm._v(_vm._s(data.value))]
                              )
                            : _c("span", { staticClass: "tw-capitalize" }, [
                                _vm._v(_vm._s(data.value)),
                              ]),
                        ]
                      },
                    }
                  : null,
                this.$route.name == "home" || this.$route.name == "patients"
                  ? {
                      key: "cell(treatment)",
                      fn: function (data) {
                        return [
                          _vm.$route.name == "home"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "white-space": "break-spaces",
                                    width: "300px",
                                  },
                                },
                                [_vm._v(" " + _vm._s(data.item.symptoms) + " ")]
                              )
                            : _vm._e(),
                          _vm.$route.name == "patients"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "white-space": "break-spaces",
                                    width: "300px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(data.item.treatment) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    }
                  : null,
                this.$route.name == "prescriptions"
                  ? {
                      key: "cell(condition)",
                      fn: function (data) {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "white-space": "break-spaces",
                                width: "300px",
                              },
                            },
                            [_vm._v(" " + _vm._s(data.item.condition) + " ")]
                          ),
                        ]
                      },
                    }
                  : null,
                this.$route.name == "patient-profile" ||
                this.$route.name == "treatments"
                  ? {
                      key: "cell(details)",
                      fn: function (data) {
                        return [
                          _c("div", { staticClass: "ml-3" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:;" },
                                on: { click: data.toggleDetails },
                              },
                              [
                                !data.detailsShowing
                                  ? _c("b-icon", {
                                      class: !data.item.can_restart
                                        ? "text-muted"
                                        : null,
                                      attrs: { icon: "chevron-right" },
                                    })
                                  : _c("b-icon", {
                                      class: !data.item.can_restart
                                        ? "text-muted"
                                        : null,
                                      attrs: { icon: "chevron-down" },
                                    }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    }
                  : null,
                this.$route.name == "patient-profile" ||
                this.$route.name == "treatments"
                  ? {
                      key: "row-details",
                      fn: function (data) {
                        return [
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { cols: "3" } }, [
                                _c("b", [
                                  _vm._v(_vm._s(data.item.medicinename)),
                                ]),
                                _c("br"),
                                _c(
                                  "span",
                                  { staticClass: "text-small text-muted" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getRefillString(data.item))
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass: "pl-5 ml-4",
                                  attrs: { cols: "5" },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-small text-muted",
                                          },
                                          [_vm._v("Prescribed")]
                                        ),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getPrescriptionDetailsDate(
                                                data.item.timestampcreated
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("b-col", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-small text-muted",
                                          },
                                          [_vm._v("Treatment Start")]
                                        ),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getPrescriptionDetailsDate(
                                                  data.item
                                                    .scheduleddatetimestamp
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "pl-3 ml-2 text-right",
                                  attrs: { cols: "2" },
                                },
                                [
                                  data.item.prescriptionstatus == "CANCELLED"
                                    ? _c(
                                        "span",
                                        {
                                          attrs: {
                                            id: `cancellation-target-${data.item.id}`,
                                          },
                                        },
                                        [
                                          Object.hasOwn(
                                            data.item,
                                            "can_restart"
                                          )
                                            ? _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.item
                                                          .prescriptionstatus
                                                      ) +
                                                      " (STRIPE) "
                                                  ),
                                                ]
                                              )
                                            : !!data.item.cancelledbyrole
                                            ? _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.item
                                                          .prescriptionstatus
                                                      ) +
                                                      " (" +
                                                      _vm._s(
                                                        data.item
                                                          .cancelledbyrole
                                                      ) +
                                                      ") "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.item
                                                          .prescriptionstatus
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                          _c("br"),
                                          Object.hasOwn(
                                            data.item,
                                            "can_restart"
                                          )
                                            ? _c(
                                                "span",
                                                { staticClass: "text-small" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.item
                                                          .prescriptionstatus
                                                      ) +
                                                      " (STRIPE) "
                                                  ),
                                                ]
                                              )
                                            : !!data.item.cancelledby
                                            ? _c(
                                                "span",
                                                { staticClass: "text-small" },
                                                [
                                                  _vm._v(
                                                    " Cancelled by " +
                                                      _vm._s(
                                                        data.item.cancelledby
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : !!data.item.cancelledbyrole
                                            ? _c(
                                                "span",
                                                { staticClass: "text-small" },
                                                [
                                                  _vm._v(" Cancelled by "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-capitalize",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          data.item
                                                            .cancelledbyrole
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          data.item.cancellationreason
                                            ? _c(
                                                "b-tooltip",
                                                {
                                                  attrs: {
                                                    target: `cancellation-target-${data.item.id}`,
                                                    triggers: "hover",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getCancellationReason(
                                                          data.item
                                                            .cancellationreason
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "text-small text-muted",
                                  attrs: { cols: "3" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(data.item.dosage) +
                                      " | " +
                                      _vm._s(data.item.unit) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "pl-5 ml-4",
                                  attrs: { cols: "5" },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-small text-muted",
                                          },
                                          [_vm._v("Next Refill")]
                                        ),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              data.item.transactiontype !==
                                                "one_time"
                                                ? _vm.getPrescriptionDetailsDate(
                                                    data.item.nextshipmentdate
                                                  )
                                                : "-"
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("b-col", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-small text-muted",
                                          },
                                          [_vm._v("Last Refill Ends")]
                                        ),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getPrescriptionDetailsDate(
                                                data.item.actualenddate
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "pl-3 ml-2 tw-flex tw-justify-end",
                                  attrs: { cols: "2" },
                                },
                                [
                                  data.item.can_restart
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "text-capitalize",
                                          staticStyle: { height: "30px" },
                                          attrs: {
                                            variant: "outline-secondary",
                                            pill: "",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "open-restart-treatment",
                                                data.item.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Restart Treatment ")]
                                      )
                                    : _vm._e(),
                                  data.item.prescriptionstatus === "COMPLETED"
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "text-capitalize",
                                          staticStyle: { height: "30px" },
                                          attrs: {
                                            variant: "outline-secondary",
                                            pill: "",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "open-add-refill",
                                                data.item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Add Refill ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    }
                  : null,
                this.$route.name == "prescriptions" ||
                this.$route.name == "patient-profile"
                  ? {
                      key: "cell(actionOrder)",
                      fn: function (data) {
                        return [
                          data.item.prescriptionstatus == "DRAFTED" ||
                          data.item.prescriptionstatus == "FOR CONFIRMATION"
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "mb-0 bold",
                                  attrs: {
                                    to: `/prescriptions/order/${
                                      data.item.patientid
                                        ? data.item.patientid
                                        : data.item.data.patientid
                                    }/update/${data.item.prescriptionid}`,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.item.actionOrder[0],
                                        data.item.patientid
                                          ? data.item.patientid
                                          : data.item.data.patientid,
                                        data.item.prescriptionid
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(data.item.actionOrder[0]) +
                                      " | "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          data.item.prescriptionstatus == "FAILED"
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.retryPrescribe",
                                      modifiers: { retryPrescribe: true },
                                    },
                                  ],
                                  staticClass: "mb-0 bold",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.item.actionOrder[0],
                                        data.item.patientid
                                          ? data.item.patientid
                                          : data.item.data.patientid,
                                        data.item.prescriptionid
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(data.item.actionOrder[0]) +
                                      " " +
                                      _vm._s(
                                        data.item.actionOrder[0] === "Cancel"
                                          ? ""
                                          : "|"
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          data.item.prescriptionstatus == "FAILED"
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.cancelPrescribe",
                                      modifiers: { cancelPrescribe: true },
                                    },
                                  ],
                                  staticClass: "mb-0 bold",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.item.actionOrder[1],
                                        data.item.patientid
                                          ? data.item.patientid
                                          : data.item.data.patientid,
                                        data.item.prescriptionid,
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(data.item.actionOrder[1]) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          data.item.prescriptionstatus == "ACCEPTED"
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.updatePrescribe",
                                      modifiers: { updatePrescribe: true },
                                    },
                                  ],
                                  staticClass: "mb-0 bold",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.item.actionOrder[0], //< previously index 1 [1]
                                        data.item.patientid
                                          ? data.item.patientid
                                          : data.item.data.patientid,
                                        data.item.prescriptionid,
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(data.item.actionOrder[0]) + " ")]
                              )
                            : _vm._e(),
                          data.item.prescriptionstatus == "DECLINED"
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.deletePrescribe",
                                      modifiers: { deletePrescribe: true },
                                    },
                                  ],
                                  staticClass: "mb-0 bold",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.item.actionOrder[0],
                                        data.item.patientid
                                          ? data.item.patientid
                                          : data.item.data.patientid,
                                        data.item.prescriptionid,
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(data.item.actionOrder[0]))]
                              )
                            : _vm._e(),
                          data.item.prescriptionstatus == "DRAFTED"
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.updatePrescribe",
                                      modifiers: { updatePrescribe: true },
                                    },
                                  ],
                                  staticClass: "mb-0 bold",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.item.actionOrder[1],
                                        data.item.patientid
                                          ? data.item.patientid
                                          : data.item.data.patientid,
                                        data.item.prescriptionid,
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(data.item.actionOrder[1]) + " |"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          data.item.prescriptionstatus == "PRESCRIBED"
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.cancelPrescribe",
                                      modifiers: { cancelPrescribe: true },
                                    },
                                  ],
                                  staticClass: "mb-0 bold",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.item.actionOrder[0],
                                        data.item.patientid
                                          ? data.item.patientid
                                          : data.item.data.patientid,
                                        data.item.prescriptionid,
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(data.item.actionOrder[0]))]
                              )
                            : _vm._e(),
                          data.item.prescriptionstatus == "FOR CONFIRMATION"
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName:
                                        "v-b-modal.sendPrescriptionConfirmation",
                                      modifiers: {
                                        sendPrescriptionConfirmation: true,
                                      },
                                    },
                                  ],
                                  staticClass: "mb-0 bold",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.item.actionOrder[1],
                                        data.item.patientid
                                          ? data.item.patientid
                                          : data.item.data.patientid,
                                        data.item.prescriptionid,
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(data.item.actionOrder[1]) + " |"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          data.item.prescriptionstatus == "FOLLOW-UP"
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.continuePrescribe",
                                      modifiers: { continuePrescribe: true },
                                    },
                                  ],
                                  staticClass: "mb-0 bold",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.item.actionOrder[0],
                                        data.item.patientid
                                          ? data.item.patientid
                                          : data.item.data.patientid,
                                        data.item.prescriptionid,
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(data.item.actionOrder[0]) + " |"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          data.item.prescriptionstatus == "FOLLOW-UP"
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.stopPrescribe",
                                      modifiers: { stopPrescribe: true },
                                    },
                                  ],
                                  staticClass: "mb-0 bold",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.item.actionOrder[1],
                                        data.item.patientid
                                          ? data.item.patientid
                                          : data.item.data.patientid,
                                        data.item.prescriptionid,
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(data.item.actionOrder[1]) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          data.item.prescriptionstatus == "REFILL"
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.updatePrescribe",
                                      modifiers: { updatePrescribe: true },
                                    },
                                  ],
                                  staticClass: "mb-0 bold",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.item.actionOrder[0],
                                        data.item.patientid
                                          ? data.item.patientid
                                          : data.item.data.patientid,
                                        data.item.prescriptionid,
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(data.item.actionOrder[0]) + " |"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          data.item.prescriptionstatus == "REFILL"
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.cancelPrescribe",
                                      modifiers: { cancelPrescribe: true },
                                    },
                                  ],
                                  staticClass: "mb-0 bold",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.item.actionOrder[1],
                                        data.item.patientid
                                          ? data.item.patientid
                                          : data.item.data.patientid,
                                        data.item.prescriptionid,
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(data.item.actionOrder[1]) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          data.item.prescriptionstatus == "COMPLETED"
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "mb-0 bold",
                                  attrs: {
                                    to:
                                      data.item.followupanswered != undefined &&
                                      data.item.followupanswered === true
                                        ? `/prescriptions/order/${
                                            data.item.patientid
                                              ? data.item.patientid
                                              : data.item.data.patientid
                                          }/update/${data.item.prescriptionid}`
                                        : "",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.item.actionOrder[0],
                                        data.item.patientid
                                          ? data.item.patientid
                                          : data.item.data.patientid,
                                        data.item.prescriptionid
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(data.item.actionOrder[0]) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          data.item.prescriptionstatus == "DRAFTED" ||
                          data.item.prescriptionstatus == "FOR CONFIRMATION"
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.deletePrescribe",
                                      modifiers: { deletePrescribe: true },
                                    },
                                  ],
                                  staticClass: "mb-0 bold",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.item.actionOrder[2],
                                        data.item.patientid
                                          ? data.item.patientid
                                          : data.item.data.patientid,
                                        data.item.prescriptionid,
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(data.item.actionOrder[2]))]
                              )
                            : _vm._e(),
                        ]
                      },
                    }
                  : null,
                {
                  key: "cell(date)",
                  fn: function (data) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.parseDate(data.value, "unix")) + " "
                      ),
                    ]
                  },
                },
                this.$route.name == "patient-profile" ||
                _vm.$route.name == "treatments"
                  ? {
                      key: "cell(datecreated)",
                      fn: function (data) {
                        return [
                          data.item.prescriptionstatus === "SCHEDULED"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.parseDate(
                                        data.item
                                          .timestampsubscriptionstartdate,
                                        "unix"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parseDate(
                                      data.item.timestampcreated,
                                      "unix",
                                      "-"
                                    )
                                  )
                                ),
                              ]),
                        ]
                      },
                    }
                  : null,
                this.$route.name == "patient-profile" ||
                _vm.$route.name == "treatments"
                  ? {
                      key: "cell(dosage)",
                      fn: function (data) {
                        return [
                          _vm._v(" " + _vm._s(`${data.item.dosage}`) + " "),
                        ]
                      },
                    }
                  : null,
                this.$route.name == "patients"
                  ? {
                      key: "cell(name)",
                      fn: function (data) {
                        return [
                          _c(
                            "b-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("b-avatar", {
                                    attrs: { src: data.value.img },
                                  }),
                                ],
                                1
                              ),
                              _c("b-col", { staticClass: "pl-3" }, [
                                _c("p", { staticClass: "mb-0" }, [
                                  _vm._v(_vm._s(data.value.fullName)),
                                ]),
                                _c("small", { staticClass: "text-muted" }, [
                                  _c(
                                    "span",
                                    { staticClass: "d-inline-block mr-2" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.value.state) + " "
                                      ),
                                    ]
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.parseDate(
                                            data.value.birthday,
                                            null,
                                            "/"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    }
                  : null,
                {
                  key: "cell(action)",
                  fn: function (data) {
                    return [
                      _vm.$route.name == "home"
                        ? _c(
                            "div",
                            [
                              data.value == "Verify ID"
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: `/verification/${data.item.id}`,
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.action(
                                            data.value,
                                            data.item.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(data.value) + " ")]
                                  )
                                : data.value == "Message"
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: `/patients/${data.item.id}/message`,
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.action(
                                            data.value,
                                            data.item.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(data.value))]
                                  )
                                : data.value == "Message, Ignore"
                                ? _c(
                                    "span",
                                    { staticClass: "text-secondary" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: `/patients/${data.item.id}/message`,
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.action(
                                                data.value,
                                                data.item.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Message ")]
                                      ),
                                      _vm._v(" | "),
                                      _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName: "v-b-modal.clearPatient",
                                              modifiers: { clearPatient: true },
                                            },
                                          ],
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.action(
                                                data.value,
                                                data.item.id,
                                                null,
                                                data.item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Ignore ")]
                                      ),
                                    ],
                                    1
                                  )
                                : data.value == "Follow-Up, Ignore"
                                ? _c(
                                    "span",
                                    { staticClass: "text-secondary" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: `/patients/profile/${data.item.id}`,
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.action(
                                                data.value,
                                                data.item.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Follow-Up ")]
                                      ),
                                      _vm._v(" | "),
                                      _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName: "v-b-modal.clearPatient",
                                              modifiers: { clearPatient: true },
                                            },
                                          ],
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.action(
                                                data.value,
                                                data.item.id,
                                                null,
                                                data.item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Ignore ")]
                                      ),
                                    ],
                                    1
                                  )
                                : data.value == "RX Refill, Ignore"
                                ? _c(
                                    "span",
                                    { staticClass: "text-secondary" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: `/patients/profile/${data.item.id}`,
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.action(
                                                data.value,
                                                data.item.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" RX Refill ")]
                                      ),
                                      _vm._v(" | "),
                                      _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName: "v-b-modal.clearPatient",
                                              modifiers: { clearPatient: true },
                                            },
                                          ],
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.action(
                                                data.value,
                                                data.item.id,
                                                null,
                                                data.item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Ignore ")]
                                      ),
                                    ],
                                    1
                                  )
                                : data.value == "RX Request, Ignore"
                                ? _c(
                                    "span",
                                    { staticClass: "text-secondary" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: `/patients/profile/${data.item.id}`,
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.action(
                                                data.value,
                                                data.item.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" RX Request ")]
                                      ),
                                      _vm._v(" | "),
                                      _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName: "v-b-modal.clearPatient",
                                              modifiers: { clearPatient: true },
                                            },
                                          ],
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.action(
                                                data.value,
                                                data.item.id,
                                                null,
                                                data.item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Ignore ")]
                                      ),
                                    ],
                                    1
                                  )
                                : data.value == "Accept"
                                ? _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.action(
                                            data.value,
                                            data.item.id,
                                            null,
                                            data.item
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(data.value))]
                                  )
                                : _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.action(
                                            data.value,
                                            data.item.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(data.value))]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$route.name == "patients"
                        ? _c(
                            "div",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: `/patients/profile/${data.item.value}`,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.action(
                                        data.value,
                                        data.item.value
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        data.value == "profile"
                                          ? "View Profile"
                                          : data.value
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(status)",
                  fn: function (data) {
                    return [
                      data.item.messagecount > 0
                        ? _c("span", { staticClass: "ml-3" }, [
                            _vm._v(" New Message "),
                            _c("span", { staticClass: "ml-2 dot" }),
                          ])
                        : _c("span", { staticClass: "ml-3" }, [
                            _vm._v(" " + _vm._s(data.item.status) + " "),
                            data.item.status == "New Message" &&
                            data.item.action !== "Message, Ignore"
                              ? _c("span", { staticClass: "ml-2 dot" })
                              : _vm._e(),
                          ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
      _vm.view == "grid"
        ? _c(
            "div",
            [
              _vm.tableItem.length
                ? _c(
                    "b-container",
                    {
                      staticClass: "pt-4",
                      style: {
                        height: _vm.scrollable ? `${_vm.scrollHeight}` : "",
                        overflow: "auto",
                      },
                      attrs: { fluid: "" },
                      on: { scroll: _vm.onScroll },
                    },
                    [
                      _c(
                        "b-row",
                        _vm._l(_vm.tableItem, function (item, i) {
                          return _c(
                            "b-col",
                            {
                              key: i,
                              attrs: { cols: "12", sm: "6", lg: "6", xl: "3" },
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "checkbox",
                                  attrs: { for: "checkbox-" + i },
                                },
                                [
                                  _c(
                                    "b-card",
                                    {
                                      staticClass: "grid",
                                      staticStyle: { border: "0" },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "pr-0",
                                              attrs: {
                                                cols: "auto",
                                                "align-self": "stretch",
                                              },
                                            },
                                            [
                                              _c("b-avatar", {
                                                attrs: {
                                                  src: item.name.img,
                                                  size: "50px",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticStyle: {
                                                "min-height": "80px",
                                              },
                                              attrs: {
                                                "align-self": "stretch",
                                              },
                                            },
                                            [
                                              _c(
                                                "h4",
                                                {
                                                  directives: [
                                                    {
                                                      name: "line-clamp",
                                                      rawName: "v-line-clamp",
                                                      value: 1,
                                                      expression: "1",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "mb-1 bold color-inherit",
                                                  attrs: {
                                                    title: item.name.fullName,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.name.fullName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "line-clamp",
                                                      rawName: "v-line-clamp",
                                                      value: 2,
                                                      expression: "2",
                                                    },
                                                  ],
                                                },
                                                [
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-muted d-block",
                                                      attrs: {
                                                        title:
                                                          item.name.birthday,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "d-inline-block mr-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.name.state
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.parseDate(
                                                                item.name
                                                                  .birthday,
                                                                null,
                                                                "/"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-muted d-block",
                                                      attrs: {
                                                        title: item.treatment,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.treatment)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-muted d-block",
                                                      attrs: {
                                                        title: item.medication,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.medication)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "text-muted d-block",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.phoneNumber)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          !item.value
                                            ? _c(
                                                "b-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  _c("b-form-checkbox", {
                                                    staticClass: "c-checkbox",
                                                    attrs: {
                                                      id: "checkbox-" + i,
                                                      value: item.value,
                                                    },
                                                    model: {
                                                      value: _vm.selected,
                                                      callback: function ($$v) {
                                                        _vm.selected = $$v
                                                      },
                                                      expression: "selected",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "mt-3",
                                          attrs: {
                                            "align-v": "center",
                                            "align-h": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: `/patients/profile/${item.value}`,
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.action(
                                                        item.action,
                                                        item.value
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s("View Profile") +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "deletePrescribe",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-enforce-focus": "",
          },
          on: { hidden: _vm.onDeletePrescribeModalHide },
        },
        [
          _c("h1", { staticClass: "heading p-4 mb-0" }, [
            _vm._v("Delete Prescription"),
          ]),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-v": "center" } },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _vm._v(" Are you sure you want to Delete Prescription? "),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { pill: "", block: "", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$bvModal.hide("deletePrescribe")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        pill: "",
                        block: "",
                        disabled: _vm.isProcessing,
                        variant: "outline-primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleModal("deletePrescribe")
                        },
                      },
                    },
                    [
                      _vm.isProcessing
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _c("span", [_vm._v("Confirm")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "warnFollowUp",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
          },
        },
        [
          _c("h1", { staticClass: "heading p-4 mb-0" }, [
            _vm._v("Cannot Proceed with refill"),
          ]),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-v": "center" } },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _vm._v(
                  " Follow up questions were not answered by the patient yet. "
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { pill: "", block: "", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$bvModal.hide("warnFollowUp")
                        },
                      },
                    },
                    [_vm._v("Okay")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "cancelPrescribe",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-enforce-focus": "",
          },
          on: { hidden: _vm.onCancelPrescribeModalHide },
        },
        [
          _c("h1", { staticClass: "heading p-4 mb-0" }, [
            _vm._v("Cancel Prescription"),
          ]),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-v": "center" } },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _vm._v(
                  " Are you sure you want to Cancel Prescription? If you cancel this prescription, the patient's treatment will be stopped immediately. Please advice the patient that canceling doesn't issue a refund."
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { pill: "", block: "", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$bvModal.hide("cancelPrescribe")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        pill: "",
                        block: "",
                        disabled: _vm.isProcessing,
                        variant: "outline-primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleModal("cancelPrescribe")
                        },
                      },
                    },
                    [
                      _vm.isProcessing
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _c("span", [_vm._v("Confirm")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "stopPrescribe",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-enforce-focus": "",
          },
        },
        [
          _c("h1", { staticClass: "heading p-4 mb-0" }, [
            _vm._v("Stop Prescription"),
          ]),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-v": "center" } },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _vm._v(" Are you sure you want to stop this Prescription?"),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { pill: "", block: "", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$bvModal.hide("stopPrescribe")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        pill: "",
                        block: "",
                        disabled: _vm.isProcessing,
                        variant: "outline-primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleModal("stopPrescribe")
                        },
                      },
                    },
                    [
                      _vm.isProcessing
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _c("span", [_vm._v("Confirm")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "retryPrescribe",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-enforce-focus": "",
          },
          on: { hide: _vm.onRetryPrescribeModalHide },
        },
        [
          _c("h1", { staticClass: "heading p-4 mb-0" }, [
            _vm._v("Retry Prescription"),
          ]),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-v": "center" } },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _vm._v(" Are you sure you want to retry this Prescription? "),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { pill: "", block: "", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$bvModal.hide("retryPrescribe")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        pill: "",
                        block: "",
                        disabled: _vm.isRetrying,
                        variant: "outline-primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleModal("retryPrescribe")
                        },
                      },
                    },
                    [
                      _vm.isRetrying
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _c("span", [_vm._v("Confirm")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "continuePrescribe",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-enforce-focus": "",
          },
        },
        [
          _c("h1", { staticClass: "heading p-4 mb-0" }, [
            _vm._v("Continue Prescription"),
          ]),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-v": "center" } },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _vm._v(
                  " Are you sure you want to continue this Prescription? Doing this makes this prescription elligible for refill"
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { pill: "", block: "", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$bvModal.hide("continuePrescribe")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        pill: "",
                        block: "",
                        disabled: _vm.isProcessing,
                        variant: "outline-primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleModal("continuePrescribe")
                        },
                      },
                    },
                    [
                      _vm.isProcessing
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _c("span", [_vm._v("Confirm")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "sendPrescriptionConfirmation",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
          },
        },
        [
          _c("h1", { staticClass: "heading p-4 mb-0" }, [
            _vm._v("Send Prescription Confirmation"),
          ]),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-v": "center" } },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _vm._v(
                  " Once the confirmation is sent to the patient, updating this record will be disabled. Are you sure you want to send confirmation? "
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { pill: "", block: "", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$bvModal.hide(
                            "sendPrescriptionConfirmation"
                          )
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        pill: "",
                        block: "",
                        variant: "outline-primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleModal("sendPrescriptionConfirmation")
                        },
                      },
                    },
                    [_vm._v("confirm")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "updatePrescribe",
            "hide-header": "",
            "hide-header-close": "",
            centered: "",
            "hide-footer": "",
            size: "xl",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-enforce-focus": "",
          },
          on: { hidden: _vm.onUpdatePrescribeModalHide },
        },
        [
          _c(
            "b-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "b-col",
                { staticClass: "mb-0", attrs: { cols: "12" } },
                [
                  _c(
                    "b-alert",
                    {
                      staticClass: "mb-0",
                      attrs: { show: _vm.alert.age, variant: _vm.alert.type },
                      on: { "dismiss-count-down": _vm.alertTimer },
                    },
                    [_vm._v(_vm._s(_vm.alert.message))]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-0 dosepot",
                      staticStyle: { border: "0" },
                      attrs: { "no-body": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "footer",
                          fn: function () {
                            return [
                              _c(
                                "b-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "mb-3",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    pill: "",
                                                    block: "",
                                                    variant: "secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$bvModal.hide(
                                                        "updatePrescribe"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Cancel ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    pill: "",
                                                    block: "",
                                                    variant: "outline-white",
                                                    disabled: _vm.isProcessing,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toggleModal(
                                                        "updatePrescribe"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.isProcessing
                                                    ? _c("b-spinner", {
                                                        attrs: { small: "" },
                                                      })
                                                    : _c("span", [
                                                        _vm._v("Confirm"),
                                                      ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "b-card-body",
                        { style: { height: _vm.iframeHeight - 118 + "px" } },
                        [
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c("b-col", { attrs: { cols: "12" } }, [
                                _c("h3", { staticClass: "mb-1 text-white" }, [
                                  _vm._v("Allergies:"),
                                ]),
                                _c("p", [_vm._v(_vm._s(_vm.allergies))]),
                              ]),
                              _c("b-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "h4",
                                  { staticClass: "h2 mb-3 text-secondary" },
                                  [_vm._v("Winona Prescription")]
                                ),
                              ]),
                              _vm.prescription.data
                                ? [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "h3",
                                          { staticClass: "mb-0 dosepot-title" },
                                          [_vm._v("Medication:")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.prescription.data.medicinename
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "h3",
                                          { staticClass: "mb-0 dosepot-title" },
                                          [_vm._v("Dosage:")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.prescription.data.dosage
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "h3",
                                          { staticClass: "mb-0 dosepot-title" },
                                          [_vm._v("Units:")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.prescription.data.unit) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "h3",
                                          { staticClass: "mb-0 dosepot-title" },
                                          [_vm._v("Date Created:")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.moment
                                                .unix(
                                                  _vm.prescription.data
                                                    .timestampcreated
                                                )
                                                .format("MM-DD-YYYY")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                : [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "h3",
                                          { staticClass: "mb-0 dosepot-title" },
                                          [_vm._v("Medication:")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.prescription.medicinename
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "h3",
                                          { staticClass: "mb-0 dosepot-title" },
                                          [_vm._v("Dosage:")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.prescription.dosage) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "h3",
                                          { staticClass: "mb-0 dosepot-title" },
                                          [_vm._v("Units:")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.prescription.unit) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "h3",
                                          { staticClass: "mb-0 dosepot-title" },
                                          [_vm._v("Date Created:")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.moment
                                                .unix(
                                                  _vm.prescription
                                                    .timestampcreated
                                                )
                                                .format("MM-DD-YYYY")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                            ],
                            2
                          ),
                          Object.keys(_vm.dosepotPrescriptions).length > 0
                            ? _c(
                                "b-row",
                                [
                                  _c("b-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "h4",
                                      { staticClass: "h2 mb-3 text-secondary" },
                                      [_vm._v("Dosepot Prescription")]
                                    ),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "mb-0 dosepot-title" },
                                        [_vm._v("Medication:")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dosepotPrescriptions
                                              .medicationname
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "mb-0 dosepot-title" },
                                        [_vm._v("Dosage:")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dosepotPrescriptions.strength
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "mb-0 dosepot-title" },
                                        [_vm._v("Units:")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${_vm.dosepotPrescriptions.quantity} ${_vm.dosepotPrescriptions.dispenseunit}`
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "mb-0 dosepot-title" },
                                        [_vm._v("Date Created:")]
                                      ),
                                      _vm.prescription.data
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.moment
                                                    .unix(
                                                      _vm.prescription.data
                                                        .timestampcreated
                                                    )
                                                    .format("MM-DD-YYYY")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        : [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.moment
                                                    .unix(
                                                      _vm.prescription
                                                        .timestampcreated
                                                    )
                                                    .format("MM-DD-YYYY")
                                                ) +
                                                " "
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "10" } },
                [
                  _vm.loading && !this.dospatURL
                    ? [
                        _c(
                          "b-row",
                          {
                            staticStyle: { height: "350px" },
                            attrs: { "align-v": "center" },
                          },
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "12" },
                              },
                              [
                                _c("h1", { staticClass: "text-primary" }, [
                                  _vm._v("Loading Dosespot..."),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _c("iframe", {
                        style: { minHeight: _vm.iframeHeight + "px" },
                        attrs: {
                          src: this.dospatURL,
                          width: "100%",
                          height: "100%",
                          frameborder: "0",
                          scrolling: "yes",
                          allowtransparency: "true",
                        },
                      }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "clearPatient",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
          },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12", sm: "5" } }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "30",
                      height: "27",
                      viewBox: "0 0 30 27",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                        fill: "#1A6A72",
                      },
                    }),
                  ]
                ),
              ]),
              _c(
                "b-col",
                { staticClass: "py-6", attrs: { cols: "12", sm: "7" } },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "heading mb-4",
                      staticStyle: { "line-height": "30px" },
                    },
                    [
                      _vm._v(" Hide this patient until the "),
                      _c("br", { staticClass: "d-none d-sm-inline" }),
                      _vm._v(" next event? "),
                    ]
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "7" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "outline-primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleModal("clearPatient")
                                },
                              },
                            },
                            [_vm._v(" Yes ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "7" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide("clearPatient")
                                },
                              },
                            },
                            [_vm._v(" No ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <b-card header-tag="header" no-body class="mb-0 table-card" style="border: 0">
    <!-- hide this sample modal once alreadt integrated -->
    <template v-if="!noHeader" v-slot:header>
      <b-row align-v="center">
        <b-col v-if="title" :cols="hasSearch ? 'auto' : ''">
          <strong>{{ title }}</strong>
        </b-col>
        <b-col v-if="hasSearch">
          <!-- FIGURE: 2 -->
          <div class="input-group input-group-flush">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fe fe-search"></i>
              </span>
            </div>
            <input
              v-model="filter"
              class="form-control list-search"
              type="search"
              placeholder="Search"
            />
            <a
              v-if="$route.name == 'patient-profile'"
              href="javascript:;"
              class="mt-2"
              @click="$emit('toggle-show-all')"
            >
              <span v-if="!showAllPrescriptions" class="text-muted"
                >Show All</span
              >
              <span v-else>Hide Old Prescriptions</span>
            </a>
          </div>
        </b-col>
        <b-col cols="12" md="auto">
          <b-row align-v="center">
            <b-col>
              <!-- FIGURE: 1 -->
              <template v-if="filter"
                >Showing <b>{{ rows }}</b>
                <template v-if="rows == 1 || rows == 0"> result</template
                ><template v-else> results</template> for “{{
                  filter
                }}”</template
              >
              <!-- <template v-else
                >{{ indexNumber }} - {{ indexLast }} of {{ rows }}</template
              > -->
            </b-col>
            <!-- <b-col cols="auto">
              <b-pagination
                v-model="currentPage"
                :per-page="itemPerPage"
                :total-rows="rows"
                :limit="itemPerPage"
                hide-goto-end-buttons
                @click.native="pageChange(currentPage)"
              ></b-pagination>
            </b-col> -->
          </b-row>
        </b-col>
      </b-row>
    </template>
    <!-- sort-icon-left -->
    <b-table
      v-if="view == 'list'"
      ref="selectableTable"
      responsive
      select-mode="single"
      :selectable="isSelectable"
      :sticky-header="scrollable ? scrollHeight : false"
      :fields="tableHeader"
      :items="tableItem"
      :filter="hasSearch ? filter : ''"
      :current-page="hasPagination ? currentPage : ''"
      :per-page="hasPagination ? itemPerPage : ''"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :class="['table-nowrap card-table', className]"
      @filtered="onFiltered"
      @row-selected="onRowSelected"
      @scroll.native="onScroll"
    >
      <template
        v-if="this.$route.name == 'home'"
        v-slot:cell(patientInfo)="data"
      >
        <p class="mb-0">
          {{ data.item.patientInfo.fullName }}
        </p>
        <small class="text-muted d-inline-block">
          <span class="d-inline-block mr-2">
            {{ data.item.patientInfo.state }}
          </span>
          <span>
            {{ parseDate(data.item.patientInfo.birthday, null, "/") }}
          </span>
        </small>
      </template>
      <template v-slot:head(value)>
        <!-- FIGURE: 3 -->
        <b-form-checkbox
          ref="checkAll"
          v-model="selectAll"
          @change="checkAll()"
        >
        </b-form-checkbox>
      </template>
      <template v-slot:cell(value)="data">
        <!-- FIGURE: 3 -->
        <b-form-checkbox v-model="selected" :value="data.value">
        </b-form-checkbox>
      </template>
      <template
        v-if="
          this.$route.name == 'patient-profile' || $route.name == 'treatments'
        "
        v-slot:cell(prescriptionstatus)="data"
      >
        <b-badge
          style="padding: 5px; display: inline-block"
          :class="[
            'mr-2',
            data.value == 'DRAFTED' || data.value == 'FOR CONFIRMATION'
              ? 'badge-warning'
              : data.value == 'PRESCRIBED' ||
                data.value == 'CONTINUE' ||
                data.value == 'COMPLETED' ||
                data.value == 'REFILLED'
              ? 'badge-success'
              : data.value == 'CANCELLED' ||
                data.value == 'STOPPED' ||
                data.value == 'FAILED'
              ? 'badge-danger'
              : data.value == 'SCHEDULED'
              ? 'badge-purple'
              : '',
          ]"
        ></b-badge>
        <span
          v-if="
            (data.item.prescriptionstatus == 'CANCELLED' &&
              !!data.item.can_restart) ||
            data.item.prescriptionstatus == 'COMPLETED'
          "
          class="tw-capitalize text-secondary"
          >{{ data.value }}</span
        >
        <span v-else class="tw-capitalize">{{ data.value }}</span>
      </template>
      <!-- Dashboard -->
      <template
        v-if="this.$route.name == 'home' || this.$route.name == 'patients'"
        v-slot:cell(treatment)="data"
      >
        <div
          v-if="$route.name == 'home'"
          style="white-space: break-spaces; width: 300px"
        >
          {{ data.item.symptoms }}
        </div>
        <div
          v-if="$route.name == 'patients'"
          style="white-space: break-spaces; width: 300px"
        >
          {{ data.item.treatment }}
        </div>
      </template>
      <!-- Prescription -->
      <template
        v-if="this.$route.name == 'prescriptions'"
        v-slot:cell(condition)="data"
      >
        <div style="white-space: break-spaces; width: 300px">
          {{ data.item.condition }}
        </div>
      </template>
      <template
        v-if="
          this.$route.name == 'patient-profile' ||
          this.$route.name == 'treatments'
        "
        #cell(details)="data"
      >
        <div class="ml-3">
          <a href="javascript:;" @click="data.toggleDetails">
            <b-icon
              v-if="!data.detailsShowing"
              :class="!data.item.can_restart ? 'text-muted' : null"
              icon="chevron-right"
            ></b-icon>
            <b-icon
              v-else
              :class="!data.item.can_restart ? 'text-muted' : null"
              icon="chevron-down"
            ></b-icon>
          </a>
        </div>
      </template>
      <template
        v-if="
          this.$route.name == 'patient-profile' ||
          this.$route.name == 'treatments'
        "
        #row-details="data"
      >
        <b-row>
          <b-col cols="3">
            <b>{{ data.item.medicinename }}</b
            ><br />
            <span class="text-small text-muted">{{
              getRefillString(data.item)
            }}</span>
          </b-col>
          <b-col cols="5" class="pl-5 ml-4">
            <b-row>
              <b-col>
                <span class="text-small text-muted">Prescribed</span><br />
                <span>{{
                  getPrescriptionDetailsDate(data.item.timestampcreated)
                }}</span>
              </b-col>
              <b-col>
                <span class="text-small text-muted">Treatment Start</span><br />
                <span>
                  {{
                    getPrescriptionDetailsDate(data.item.scheduleddatetimestamp)
                  }}
                </span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2" class="pl-3 ml-2 text-right">
            <span
              v-if="data.item.prescriptionstatus == 'CANCELLED'"
              :id="`cancellation-target-${data.item.id}`"
            >
              <span
                v-if="Object.hasOwn(data.item, 'can_restart')"
                class="text-danger"
              >
                {{ data.item.prescriptionstatus }} (STRIPE)
              </span>
              <span v-else-if="!!data.item.cancelledbyrole" class="text-danger">
                {{ data.item.prescriptionstatus }} ({{
                  data.item.cancelledbyrole
                }})
              </span>
              <span v-else class="text-danger">
                {{ data.item.prescriptionstatus }}
              </span>
              <br />
              <span
                v-if="Object.hasOwn(data.item, 'can_restart')"
                class="text-small"
              >
                {{ data.item.prescriptionstatus }} (STRIPE)
              </span>
              <span v-else-if="!!data.item.cancelledby" class="text-small">
                Cancelled by {{ data.item.cancelledby }}
              </span>
              <span v-else-if="!!data.item.cancelledbyrole" class="text-small">
                Cancelled by
                <span class="text-capitalize">{{
                  data.item.cancelledbyrole
                }}</span>
              </span>
              <b-tooltip
                v-if="data.item.cancellationreason"
                :target="`cancellation-target-${data.item.id}`"
                triggers="hover"
              >
                {{ getCancellationReason(data.item.cancellationreason) }}
              </b-tooltip>
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col class="text-small text-muted" cols="3">
            {{ data.item.dosage }} | {{ data.item.unit }}
          </b-col>
          <b-col cols="5" class="pl-5 ml-4">
            <b-row>
              <b-col>
                <span class="text-small text-muted">Next Refill</span><br />
                <span>{{
                  data.item.transactiontype !== "one_time"
                    ? getPrescriptionDetailsDate(data.item.nextshipmentdate)
                    : "-"
                }}</span>
              </b-col>
              <b-col>
                <span class="text-small text-muted">Last Refill Ends</span
                ><br />
                <span>{{
                  getPrescriptionDetailsDate(data.item.actualenddate)
                }}</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2" class="pl-3 ml-2 tw-flex tw-justify-end">
            <!-- Restart Treatment Button Here -->
            <b-button
              v-if="data.item.can_restart"
              variant="outline-secondary"
              class="text-capitalize"
              pill
              size="sm"
              style="height: 30px"
              @click="$emit('open-restart-treatment', data.item.id)"
            >
              Restart Treatment
            </b-button>
            <b-button
              v-if="data.item.prescriptionstatus === 'COMPLETED'"
              variant="outline-secondary"
              class="text-capitalize"
              pill
              size="sm"
              style="height: 30px"
              @click="$emit('open-add-refill', data.item)"
            >
              Add Refill
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template
        v-if="
          this.$route.name == 'prescriptions' ||
          this.$route.name == 'patient-profile'
        "
        v-slot:cell(actionOrder)="data"
      >
        <router-link
          v-if="
            data.item.prescriptionstatus == 'DRAFTED' ||
            data.item.prescriptionstatus == 'FOR CONFIRMATION'
          "
          class="mb-0 bold"
          :to="`/prescriptions/order/${
            data.item.patientid ? data.item.patientid : data.item.data.patientid
          }/update/${data.item.prescriptionid}`"
          @click.native="
            action(
              data.item.actionOrder[0],
              data.item.patientid
                ? data.item.patientid
                : data.item.data.patientid,
              data.item.prescriptionid
            )
          "
        >
          {{ data.item.actionOrder[0] }} |
        </router-link>
        <a
          v-if="data.item.prescriptionstatus == 'FAILED'"
          v-b-modal.retryPrescribe
          class="mb-0 bold"
          href="#"
          @click="
            action(
              data.item.actionOrder[0],
              data.item.patientid
                ? data.item.patientid
                : data.item.data.patientid,
              data.item.prescriptionid
            )
          "
        >
          {{ data.item.actionOrder[0] }}
          {{ data.item.actionOrder[0] === "Cancel" ? "" : "|" }}
        </a>
        <a
          v-if="data.item.prescriptionstatus == 'FAILED'"
          v-b-modal.cancelPrescribe
          class="mb-0 bold"
          href="#"
          @click="
            action(
              data.item.actionOrder[1],
              data.item.patientid
                ? data.item.patientid
                : data.item.data.patientid,
              data.item.prescriptionid,
              data.item
            )
          "
        >
          {{ data.item.actionOrder[1] }}
        </a>
        <a
          v-if="data.item.prescriptionstatus == 'ACCEPTED'"
          v-b-modal.updatePrescribe
          class="mb-0 bold"
          href="#"
          @click="
            action(
              data.item.actionOrder[0], //< previously index 1 [1]
              data.item.patientid
                ? data.item.patientid
                : data.item.data.patientid,
              data.item.prescriptionid,
              data.item
            )
          "
          >{{ data.item.actionOrder[0] }}
        </a>
        <a
          v-if="data.item.prescriptionstatus == 'DECLINED'"
          v-b-modal.deletePrescribe
          class="mb-0 bold"
          href="#"
          @click="
            action(
              data.item.actionOrder[0],
              data.item.patientid
                ? data.item.patientid
                : data.item.data.patientid,
              data.item.prescriptionid,
              data.item
            )
          "
        >
          {{ data.item.actionOrder[0] }}</a
        >
        <a
          v-if="data.item.prescriptionstatus == 'DRAFTED'"
          v-b-modal.updatePrescribe
          class="mb-0 bold"
          href="#"
          @click="
            action(
              data.item.actionOrder[1],
              data.item.patientid
                ? data.item.patientid
                : data.item.data.patientid,
              data.item.prescriptionid,
              data.item
            )
          "
          >{{ data.item.actionOrder[1] }} |</a
        >
        <a
          v-if="data.item.prescriptionstatus == 'PRESCRIBED'"
          v-b-modal.cancelPrescribe
          class="mb-0 bold"
          href="#"
          @click="
            action(
              data.item.actionOrder[0],
              data.item.patientid
                ? data.item.patientid
                : data.item.data.patientid,
              data.item.prescriptionid,
              data.item
            )
          "
          >{{ data.item.actionOrder[0] }}</a
        >

        <a
          v-if="data.item.prescriptionstatus == 'FOR CONFIRMATION'"
          v-b-modal.sendPrescriptionConfirmation
          class="mb-0 bold"
          href="#"
          @click="
            action(
              data.item.actionOrder[1],
              data.item.patientid
                ? data.item.patientid
                : data.item.data.patientid,
              data.item.prescriptionid,
              data.item
            )
          "
          >{{ data.item.actionOrder[1] }} |</a
        >

        <a
          v-if="data.item.prescriptionstatus == 'FOLLOW-UP'"
          v-b-modal.continuePrescribe
          class="mb-0 bold"
          href="#"
          @click="
            action(
              data.item.actionOrder[0],
              data.item.patientid
                ? data.item.patientid
                : data.item.data.patientid,
              data.item.prescriptionid,
              data.item
            )
          "
          >{{ data.item.actionOrder[0] }} |</a
        >
        <a
          v-if="data.item.prescriptionstatus == 'FOLLOW-UP'"
          v-b-modal.stopPrescribe
          class="mb-0 bold"
          href="#"
          @click="
            action(
              data.item.actionOrder[1],
              data.item.patientid
                ? data.item.patientid
                : data.item.data.patientid,
              data.item.prescriptionid,
              data.item
            )
          "
        >
          {{ data.item.actionOrder[1] }}
        </a>

        <a
          v-if="data.item.prescriptionstatus == 'REFILL'"
          v-b-modal.updatePrescribe
          class="mb-0 bold"
          href="#"
          @click="
            action(
              data.item.actionOrder[0],
              data.item.patientid
                ? data.item.patientid
                : data.item.data.patientid,
              data.item.prescriptionid,
              data.item
            )
          "
          >{{ data.item.actionOrder[0] }} |</a
        >
        <a
          v-if="data.item.prescriptionstatus == 'REFILL'"
          v-b-modal.cancelPrescribe
          class="mb-0 bold"
          href="#"
          @click="
            action(
              data.item.actionOrder[1],
              data.item.patientid
                ? data.item.patientid
                : data.item.data.patientid,
              data.item.prescriptionid,
              data.item
            )
          "
        >
          {{ data.item.actionOrder[1] }}
        </a>

        <router-link
          v-if="data.item.prescriptionstatus == 'COMPLETED'"
          class="mb-0 bold"
          :to="
            data.item.followupanswered != undefined &&
            data.item.followupanswered === true
              ? `/prescriptions/order/${
                  data.item.patientid
                    ? data.item.patientid
                    : data.item.data.patientid
                }/update/${data.item.prescriptionid}`
              : ''
          "
          @click.native="
            action(
              data.item.actionOrder[0],
              data.item.patientid
                ? data.item.patientid
                : data.item.data.patientid,
              data.item.prescriptionid
            )
          "
        >
          {{ data.item.actionOrder[0] }}
        </router-link>

        <a
          v-if="
            data.item.prescriptionstatus == 'DRAFTED' ||
            data.item.prescriptionstatus == 'FOR CONFIRMATION'
          "
          v-b-modal.deletePrescribe
          class="mb-0 bold"
          href="#"
          @click="
            action(
              data.item.actionOrder[2],
              data.item.patientid
                ? data.item.patientid
                : data.item.data.patientid,
              data.item.prescriptionid,
              data.item
            )
          "
        >
          {{ data.item.actionOrder[2] }}</a
        >
      </template>
      <!-- Patient Profile -->
      <template v-slot:cell(date)="data">
        {{ parseDate(data.value, "unix") }}
      </template>
      <template
        v-if="
          this.$route.name == 'patient-profile' || $route.name == 'treatments'
        "
        v-slot:cell(datecreated)="data"
      >
        <span v-if="data.item.prescriptionstatus === 'SCHEDULED'">
          {{ parseDate(data.item.timestampsubscriptionstartdate, "unix") }}
        </span>
        <span v-else>{{
          parseDate(data.item.timestampcreated, "unix", "-")
        }}</span>
      </template>
      <template
        v-if="
          this.$route.name == 'patient-profile' || $route.name == 'treatments'
        "
        v-slot:cell(dosage)="data"
      >
        {{ `${data.item.dosage}` }}
      </template>
      <!-- Patients -->
      <template v-if="this.$route.name == 'patients'" v-slot:cell(name)="data">
        <b-row no-gutters>
          <b-col cols="auto">
            <b-avatar :src="data.value.img"></b-avatar>
          </b-col>
          <b-col class="pl-3">
            <p class="mb-0">{{ data.value.fullName }}</p>
            <small class="text-muted">
              <span class="d-inline-block mr-2">
                {{ data.value.state }}
              </span>
              <span>
                {{ parseDate(data.value.birthday, null, "/") }}
              </span>
            </small>
          </b-col>
        </b-row>
      </template>
      <template
        v-if="this.$route.name == 'patients'"
        v-slot:cell(action)="data"
      >
        <router-link
          :to="`/patients/profile/${data.item.value}`"
          @click.native="action(data.value, data.item.value)"
        >
          {{ data.value == "profile" ? "View Profile" : data.value }}
        </router-link>
      </template>
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <!-- <template v-slot:cell(messagecount)="data"></template> -->
      <template v-slot:cell(status)="data">
        <span v-if="data.item.messagecount > 0" class="ml-3">
          New Message
          <span class="ml-2 dot"></span>
        </span>
        <span v-else class="ml-3">
          {{ data.item.status }}
          <span
            v-if="
              data.item.status == 'New Message' &&
              data.item.action !== 'Message, Ignore'
            "
            class="ml-2 dot"
          ></span>
        </span>
      </template>
      <template v-slot:cell(action)="data">
        <div v-if="$route.name == 'home'">
          <router-link
            v-if="data.value == 'Verify ID'"
            :to="`/verification/${data.item.id}`"
            @click.native="action(data.value, data.item.id)"
          >
            {{ data.value }}
          </router-link>
          <router-link
            v-else-if="data.value == 'Message'"
            :to="`/patients/${data.item.id}/message`"
            @click.native="action(data.value, data.item.id)"
            >{{ data.value }}</router-link
          >
          <span
            v-else-if="data.value == 'Message, Ignore'"
            class="text-secondary"
          >
            <router-link
              :to="`/patients/${data.item.id}/message`"
              @click.native="action(data.value, data.item.id)"
            >
              Message
            </router-link>
            |
            <a
              v-b-modal.clearPatient
              href="#"
              @click="action(data.value, data.item.id, null, data.item)"
            >
              Ignore
            </a>
          </span>
          <span
            v-else-if="data.value == 'Follow-Up, Ignore'"
            class="text-secondary"
          >
            <router-link
              :to="`/patients/profile/${data.item.id}`"
              @click.native="action(data.value, data.item.id)"
            >
              Follow-Up
            </router-link>
            |
            <a
              v-b-modal.clearPatient
              href="#"
              @click="action(data.value, data.item.id, null, data.item)"
            >
              Ignore
            </a>
          </span>
          <span
            v-else-if="data.value == 'RX Refill, Ignore'"
            class="text-secondary"
          >
            <router-link
              :to="`/patients/profile/${data.item.id}`"
              @click.native="action(data.value, data.item.id)"
            >
              RX Refill
            </router-link>
            |
            <a
              v-b-modal.clearPatient
              href="#"
              @click="action(data.value, data.item.id, null, data.item)"
            >
              Ignore
            </a>
          </span>
          <span
            v-else-if="data.value == 'RX Request, Ignore'"
            class="text-secondary"
          >
            <router-link
              :to="`/patients/profile/${data.item.id}`"
              @click.native="action(data.value, data.item.id)"
            >
              RX Request
            </router-link>
            |
            <a
              v-b-modal.clearPatient
              href="#"
              @click="action(data.value, data.item.id, null, data.item)"
            >
              Ignore
            </a>
          </span>
          <a
            v-else-if="data.value == 'Accept'"
            href="#"
            @click="action(data.value, data.item.id, null, data.item)"
            >{{ data.value }}</a
          >
          <a v-else href="#" @click="action(data.value, data.item.id)">{{
            data.value
          }}</a>
        </div>
        <div v-if="$route.name == 'patients'">
          <router-link
            :to="`/patients/profile/${data.item.value}`"
            @click.native="action(data.value, data.item.value)"
          >
            {{ data.value == "profile" ? "View Profile" : data.value }}
          </router-link>
        </div>
      </template>
    </b-table>
    <div v-if="view == 'grid'">
      <b-container
        v-if="tableItem.length"
        fluid
        class="pt-4"
        :style="{
          height: scrollable ? `${scrollHeight}` : '',
          overflow: 'auto',
        }"
        @scroll="onScroll"
      >
        <b-row>
          <!-- <b-col cols="12">
            <b-row align-v="center" class="mb-3" style="margin-top: -15px">
              <b-col cols="auto">
                <b-form-checkbox
                  v-model="selectAll"
                  @change="checkAll()"
                  ref="checkAll"
                  >Move to:</b-form-checkbox
                >
              </b-col>
              <b-col cols="auto">
                <b-button variant="link">In Progress</b-button>
              </b-col>
              <b-col cols="auto">
                <b-button variant="link">Completed</b-button>
              </b-col>
              <b-col cols="auto">
                <b-button variant="link">Archive</b-button>
              </b-col>
            </b-row>
          </b-col> -->
          <b-col
            v-for="(item, i) in tableItem"
            :key="i"
            cols="12"
            sm="6"
            lg="6"
            xl="3"
          >
            <label :for="'checkbox-' + i" class="checkbox">
              <b-card class="grid" style="border: 0">
                <b-row>
                  <b-col cols="auto" class="pr-0" align-self="stretch">
                    <b-avatar :src="item.name.img" size="50px"></b-avatar>
                  </b-col>
                  <b-col align-self="stretch" style="min-height: 80px">
                    <h4
                      v-line-clamp="1"
                      class="mb-1 bold color-inherit"
                      :title="item.name.fullName"
                    >
                      {{ item.name.fullName }}
                    </h4>
                    <div v-line-clamp="2">
                      <small
                        class="text-muted d-block"
                        :title="item.name.birthday"
                      >
                        <span class="d-inline-block mr-2">
                          {{ item.name.state }}
                        </span>
                        <span>
                          {{ parseDate(item.name.birthday, null, "/") }}
                        </span>
                      </small>
                      <small
                        class="text-muted d-block"
                        :title="item.treatment"
                        >{{ item.treatment }}</small
                      >
                      <small
                        class="text-muted d-block"
                        :title="item.medication"
                        >{{ item.medication }}</small
                      >
                    </div>
                    <small class="text-muted d-block">{{
                      item.phoneNumber
                    }}</small>
                  </b-col>
                  <b-col v-if="!item.value" cols="auto">
                    <b-form-checkbox
                      :id="'checkbox-' + i"
                      v-model="selected"
                      :value="item.value"
                      class="c-checkbox"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row align-v="center" align-h="center" class="mt-3">
                  <b-col class="text-center">
                    <router-link
                      :to="`/patients/profile/${item.value}`"
                      @click.native="action(item.action, item.value)"
                    >
                      {{ "View Profile" }}
                    </router-link>
                  </b-col>
                </b-row>
              </b-card>
            </label>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal
      id="deletePrescribe"
      hide-header
      centered
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      @hidden="onDeletePrescribeModalHide"
    >
      <h1 class="heading p-4 mb-0">Delete Prescription</h1>
      <b-row align-v="center" class="p-4">
        <b-col cols="12"> Are you sure you want to Delete Prescription? </b-col>
      </b-row>

      <b-row align-h="center" class="p-4">
        <b-col cols="6">
          <b-button
            pill
            block
            variant="primary"
            @click="$bvModal.hide('deletePrescribe')"
            >Cancel</b-button
          >
        </b-col>
        <b-col cols="6" class="mb-3">
          <b-button
            pill
            block
            :disabled="isProcessing"
            variant="outline-primary"
            @click="toggleModal('deletePrescribe')"
          >
            <b-spinner v-if="isProcessing" small></b-spinner>
            <span v-else>Confirm</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="warnFollowUp" hide-header centered hide-footer size="lg">
      <h1 class="heading p-4 mb-0">Cannot Proceed with refill</h1>
      <b-row align-v="center" class="p-4">
        <b-col cols="12">
          Follow up questions were not answered by the patient yet.
        </b-col>
      </b-row>

      <b-row align-h="center" class="p-4">
        <b-col cols="12">
          <b-button
            pill
            block
            variant="primary"
            @click="$bvModal.hide('warnFollowUp')"
            >Okay</b-button
          >
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="cancelPrescribe"
      hide-header
      centered
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      @hidden="onCancelPrescribeModalHide"
    >
      <h1 class="heading p-4 mb-0">Cancel Prescription</h1>
      <b-row align-v="center" class="p-4">
        <b-col cols="12">
          Are you sure you want to Cancel Prescription? If you cancel this
          prescription, the patient's treatment will be stopped immediately.
          Please advice the patient that canceling doesn't issue a
          refund.</b-col
        >
      </b-row>

      <b-row align-h="center" class="p-4">
        <b-col cols="6">
          <b-button
            pill
            block
            variant="primary"
            @click="$bvModal.hide('cancelPrescribe')"
            >Cancel</b-button
          >
        </b-col>
        <b-col cols="6" class="mb-3">
          <b-button
            pill
            block
            :disabled="isProcessing"
            variant="outline-primary"
            @click="toggleModal('cancelPrescribe')"
          >
            <b-spinner v-if="isProcessing" small></b-spinner>
            <span v-else>Confirm</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="stopPrescribe"
      hide-header
      centered
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
    >
      <h1 class="heading p-4 mb-0">Stop Prescription</h1>
      <b-row align-v="center" class="p-4">
        <b-col cols="12">
          Are you sure you want to stop this Prescription?</b-col
        >
      </b-row>

      <b-row align-h="center" class="p-4">
        <b-col cols="6">
          <b-button
            pill
            block
            variant="primary"
            @click="$bvModal.hide('stopPrescribe')"
            >Cancel</b-button
          >
        </b-col>
        <b-col cols="6" class="mb-3">
          <b-button
            pill
            block
            :disabled="isProcessing"
            variant="outline-primary"
            @click="toggleModal('stopPrescribe')"
          >
            <b-spinner v-if="isProcessing" small></b-spinner>
            <span v-else>Confirm</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="retryPrescribe"
      hide-header
      centered
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      @hide="onRetryPrescribeModalHide"
    >
      <h1 class="heading p-4 mb-0">Retry Prescription</h1>
      <b-row align-v="center" class="p-4">
        <b-col cols="12">
          Are you sure you want to retry this Prescription?
        </b-col>
      </b-row>

      <b-row align-h="center" class="p-4">
        <b-col cols="6">
          <b-button
            pill
            block
            variant="primary"
            @click="$bvModal.hide('retryPrescribe')"
            >Cancel</b-button
          >
        </b-col>
        <b-col cols="6" class="mb-3">
          <b-button
            pill
            block
            :disabled="isRetrying"
            variant="outline-primary"
            @click="toggleModal('retryPrescribe')"
          >
            <b-spinner v-if="isRetrying" small></b-spinner>
            <span v-else>Confirm</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="continuePrescribe"
      hide-header
      centered
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
    >
      <h1 class="heading p-4 mb-0">Continue Prescription</h1>
      <b-row align-v="center" class="p-4">
        <b-col cols="12">
          Are you sure you want to continue this Prescription? Doing this makes
          this prescription elligible for refill</b-col
        >
      </b-row>

      <b-row align-h="center" class="p-4">
        <b-col cols="6">
          <b-button
            pill
            block
            variant="primary"
            @click="$bvModal.hide('continuePrescribe')"
            >Cancel</b-button
          >
        </b-col>
        <b-col cols="6" class="mb-3">
          <b-button
            pill
            block
            :disabled="isProcessing"
            variant="outline-primary"
            @click="toggleModal('continuePrescribe')"
          >
            <b-spinner v-if="isProcessing" small></b-spinner>
            <span v-else>Confirm</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="sendPrescriptionConfirmation"
      hide-header
      centered
      hide-footer
      size="lg"
    >
      <h1 class="heading p-4 mb-0">Send Prescription Confirmation</h1>
      <b-row align-v="center" class="p-4">
        <b-col cols="12">
          Once the confirmation is sent to the patient, updating this record
          will be disabled. Are you sure you want to send confirmation?
        </b-col>
      </b-row>

      <b-row align-h="center" class="p-4">
        <b-col cols="6">
          <b-button
            pill
            block
            variant="primary"
            @click="$bvModal.hide('sendPrescriptionConfirmation')"
            >Cancel</b-button
          >
        </b-col>
        <b-col cols="6" class="mb-3">
          <b-button
            pill
            block
            variant="outline-primary"
            @click="toggleModal('sendPrescriptionConfirmation')"
            >confirm</b-button
          >
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="updatePrescribe"
      hide-header
      hide-header-close
      centered
      hide-footer
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      @hidden="onUpdatePrescribeModalHide"
    >
      <!-- <h1 class="heading p-4 mb-0">Update Prescription</h1> -->
      <b-row no-gutters>
        <b-col cols="12" class="mb-0">
          <b-alert
            class="mb-0"
            :show="alert.age"
            :variant="alert.type"
            @dismiss-count-down="alertTimer"
            >{{ alert.message }}</b-alert
          >
        </b-col>
        <b-col cols="12" md="2">
          <b-card no-body style="border: 0" class="mb-0 dosepot">
            <b-card-body :style="{ height: iframeHeight - 118 + 'px' }">
              <b-row class="mb-3">
                <b-col cols="12">
                  <h3 class="mb-1 text-white">Allergies:</h3>
                  <p>{{ allergies }}</p>
                </b-col>

                <b-col cols="12">
                  <h4 class="h2 mb-3 text-secondary">Winona Prescription</h4>
                </b-col>

                <template v-if="prescription.data">
                  <b-col cols="12" class="mb-2">
                    <h3 class="mb-0 dosepot-title">Medication:</h3>
                    {{ prescription.data.medicinename }}
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <h3 class="mb-0 dosepot-title">Dosage:</h3>
                    {{ prescription.data.dosage }}
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <h3 class="mb-0 dosepot-title">Units:</h3>
                    {{ prescription.data.unit }}
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <h3 class="mb-0 dosepot-title">Date Created:</h3>
                    {{
                      moment
                        .unix(prescription.data.timestampcreated)
                        .format("MM-DD-YYYY")
                    }}
                  </b-col>
                </template>
                <template v-else>
                  <b-col cols="12" class="mb-2">
                    <h3 class="mb-0 dosepot-title">Medication:</h3>
                    {{ prescription.medicinename }}
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <h3 class="mb-0 dosepot-title">Dosage:</h3>
                    {{ prescription.dosage }}
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <h3 class="mb-0 dosepot-title">Units:</h3>
                    {{ prescription.unit }}
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <h3 class="mb-0 dosepot-title">Date Created:</h3>
                    {{
                      moment
                        .unix(prescription.timestampcreated)
                        .format("MM-DD-YYYY")
                    }}
                  </b-col>
                </template>
              </b-row>
              <b-row v-if="Object.keys(dosepotPrescriptions).length > 0">
                <b-col cols="12">
                  <h4 class="h2 mb-3 text-secondary">Dosepot Prescription</h4>
                </b-col>
                <b-col cols="12" class="mb-2">
                  <h3 class="mb-0 dosepot-title">Medication:</h3>
                  {{ dosepotPrescriptions.medicationname }}
                </b-col>
                <b-col cols="12" class="mb-2">
                  <h3 class="mb-0 dosepot-title">Dosage:</h3>
                  {{ dosepotPrescriptions.strength }}
                </b-col>
                <b-col cols="12" class="mb-2">
                  <h3 class="mb-0 dosepot-title">Units:</h3>
                  {{
                    `${dosepotPrescriptions.quantity} ${dosepotPrescriptions.dispenseunit}`
                  }}
                </b-col>
                <b-col cols="12" class="mb-2">
                  <h3 class="mb-0 dosepot-title">Date Created:</h3>
                  <template v-if="prescription.data">
                    {{
                      moment
                        .unix(prescription.data.timestampcreated)
                        .format("MM-DD-YYYY")
                    }}
                  </template>
                  <template v-else>
                    {{
                      moment
                        .unix(prescription.timestampcreated)
                        .format("MM-DD-YYYY")
                    }}
                  </template>
                </b-col>
              </b-row>
            </b-card-body>

            <template #footer>
              <b-row no-gutters>
                <b-col>
                  <b-row>
                    <b-col cols="12" class="mb-3">
                      <b-button
                        pill
                        block
                        variant="secondary"
                        @click="$bvModal.hide('updatePrescribe')"
                      >
                        Cancel
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <b-button
                        pill
                        block
                        variant="outline-white"
                        :disabled="isProcessing"
                        @click="toggleModal('updatePrescribe')"
                      >
                        <b-spinner v-if="isProcessing" small></b-spinner>
                        <span v-else>Confirm</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
        <b-col cols="12" md="10">
          <template v-if="loading && !this.dospatURL">
            <b-row align-v="center" style="height: 350px">
              <b-col cols="12" class="text-center">
                <h1 class="text-primary">Loading Dosespot...</h1>
              </b-col>
            </b-row>
          </template>
          <iframe
            v-else
            :src="this.dospatURL"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="yes"
            allowtransparency="true"
            :style="{ minHeight: iframeHeight + 'px' }"
          ></iframe>
        </b-col>
      </b-row>

      <!-- <b-row class="mb-6">
        <b-col cols="12">
          <v-icon name="icon-logo" size="30"></v-icon>
        </b-col>
      </b-row> -->
      <!-- <div class="modal-card">
        <h2 class="mb-3 heading">
          Are you sure this patient is not fit for treatment?
        </h2>
        <b-row class="text-center" align-h="center" no-gutters>
          <b-col cols="12" class="mb-3">
            <b-button
              pill
              block
              @click="toggleModal('updatePrescribe')"
              variant="outline-primary"
              >confirm</b-button
            >
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              @click="$bvModal.hide('updatePrescribe')"
              variant="primary"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
      </div> -->
    </b-modal>

    <b-modal id="clearPatient" hide-header centered hide-footer size="lg">
      <b-row>
        <b-col cols="12" sm="5">
          <svg
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
              fill="#1A6A72"
            />
          </svg>
        </b-col>
        <b-col cols="12" sm="7" class="py-6">
          <h1 class="heading mb-4" style="line-height: 30px">
            Hide this patient until the <br class="d-none d-sm-inline" />
            next event?
          </h1>
          <b-row class="mb-3">
            <b-col cols="12" sm="7">
              <b-button
                pill
                block
                variant="outline-primary"
                @click="toggleModal('clearPatient')"
              >
                Yes
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="7">
              <b-button
                pill
                block
                variant="primary"
                @click="$bvModal.hide('clearPatient')"
              >
                No
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import { PatientService } from "@/services/patient.service";
import { DashboardService } from "@/services/dashboard.service";
import { format, fromUnixTime } from "date-fns";
import moment from "moment";

export default {
  props: {
    title: {
      type: String,
    },
    className: {
      type: String,
    },
    view: {
      type: String,
      default: "list",
      validator(x) {
        return ["list", "grid"].includes(x);
      },
    },
    tableHeader: {
      type: Array,
      default: () => [],
    },
    tableItem: {
      type: Array,
      default: () => [],
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    scrollHeight: {
      type: String,
      default: "400px",
    },
    hasPagination: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    itemPerPage: {
      type: Number,
      default: 5,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    sortBy: {
      type: String,
      default: "",
    },
    sortDesc: {
      type: Boolean,
      default: true,
    },
    hasChat: {
      type: Boolean,
      default: false,
    },
    showAllPrescriptions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rows: 1,
      patientid: "",
      allergies: "",
      prescriptionid: "",
      prescription: {},
      dospatURL: "",
      loading: true,
      // sortDesc: true,
      currentPage: 1,
      indexLast: 0, // see FIGURE: 1
      indexNumber: 0, // see FIGURE: 1
      filter: null, // see FIGURE: 2
      // filterOn: [],
      selected: [], // see FIGURE: 3
      rowSelect: [],
      selectAll: false,
      alert: {},
      dosepotPrescriptions: {},
      iframeHeight: "",
      isRetrying: false,
      isProcessing: false,
      ignoreType: "",
      cancellationReasons: [],
      otherCancellationReasons: [
        { value: "change_mind", text: "Patient changed their mind" },
        { value: "change_dose", text: "Dose changed" },
        { value: "change_prescription", text: "Prescription changed" },
        { value: "side_effect", text: "Side effects" },
        { value: "other", text: "Other" },
      ],
    };
  },
  computed: {
    selectedPrescription() {
      return this.$store.state.prescription.selectedPrescription;
    },
    isPrescribeModalOpen() {
      return this.$store.state.prescription.isPrescribeModalOpen;
    },
    isResumePrescriptionModalOpen() {
      return this.$store.state.prescription.isResumePrescriptionModalOpen;
    },
    isCancelPrescriptionModalOpen() {
      return this.$store.state.prescription.isCancelPrescriptionModalOpen;
    },
    isRetryPrescriptionModalOpen() {
      return this.$store.state.prescription.isRetryPrescriptionModalOpen;
    },
    isDeletePrescriptionModalOpen() {
      return this.$store.state.prescription.isDeletePrescriptionModalOpen;
    },
  },
  watch: {
    selectedPrescription(val) {
      if (val.prescriptionstatus === "COMPLETED") {
        const selectedPrescription =
          this.$store.state.prescription.selectedPrescription;
        this.dispatchAction("Refill", selectedPrescription);

        this.$store.dispatch("prescription/setSelectedPrescription", {});

        if (selectedPrescription.followupanswered) {
          this.$router.push(
            `/prescriptions/order/${selectedPrescription.patientid}/update/${selectedPrescription.prescriptionid}`
          );
        }
      }
    },
    isPrescribeModalOpen(val) {
      if (val) {
        this.$bvModal.show("updatePrescribe");
        this.dispatchAction(
          "Prescribe",
          this.$store.state.prescription.selectedPrescription
        );
      }
    },
    isCancelPrescriptionModalOpen(val) {
      if (val) {
        this.$bvModal.show("cancelPrescribe");
        this.dispatchAction(
          "Cancel",
          this.$store.state.prescription.selectedPrescription
        );
      }
    },
    isRetryPrescriptionModalOpen(val) {
      if (val) {
        this.$bvModal.show("retryPrescribe");
        this.dispatchAction(
          "Retry",
          this.$store.state.prescription.selectedPrescription
        );
      }
    },
    isDeletePrescriptionModalOpen(val) {
      if (val) {
        this.$bvModal.show("deletePrescribe");
        this.dispatchAction(
          "Delete",
          this.$store.state.prescription.selectedPrescription
        );
      }
    },
    filter(newValue) {
      switch (this.$route.name) {
        case "patients":
          this.$emit("searchPatient", newValue);
          break;
        case "prescriptions":
          this.$emit("searchPrescription", newValue);
        case "patient-profile":
          this.$emit("searchPrescription", newValue);
        case "treatments":
          this.$emit("searchPrescription", newValue);
        default:
          break;
      }
    },
    // check all boxes see also FIGURE: 3
    selected(newValue) {
      this.selectAll = newValue.length == this.rows ? true : false;
      if (this.hasPagination)
        this.selectAll = newValue.length == this.indexLast ? true : false;
    },
  },
  mounted() {
    this.$forceUpdate();
    this.matchHeight();

    window.addEventListener("resize", () => {
      this.matchHeight();
    });
  },
  async created() {
    // see FIGURE: 1
    this.indexNumber = (this.currentPage - 1) * this.itemPerPage + 1;
    this.indexLast = this.indexNumber - 1 + this.itemPerPage;

    // display total item see FIGURE: 1 also
    this.rows = this.tableItem.length;
    // if indexLast is greater than row then display the remaining row
    if (this.indexLast > this.rows) this.indexLast = this.rows;

    const { data } = await DashboardService.getLookup("cancelreason");
    this.cancellationReasons = data;
  },
  methods: {
    matchHeight() {
      let appHeight = window.innerHeight - 18;
      this.iframeHeight = appHeight;
    },
    alertMessage(type, message, age) {
      if (!age) age = 3;
      this.alert = {
        age: age,
        type: type,
        message: message,
      };
    },
    alertTimer(count) {
      this.alert.age = count;
    },
    getCancellationReason(id) {
      const reasonobj = this.cancellationReasons.filter((o) => o.id == id);
      if (reasonobj.length > 0) {
        return reasonobj[0].name;
      } else {
        // Search secondary list
        const secondObj = this.otherCancellationReasons.filter(
          (o) => o.value == id
        );
        if (secondObj.length > 0) {
          return secondObj[0].text;
        } else {
          return id;
        }
      }
    },
    async toggleModal(id) {
      switch (id) {
        case "updatePrescribe":
          this.isProcessing = true;
          this.$store
            .dispatch("prescription/checkPrescriptionOrder", {
              patientid: this.patientid,
              prescriptionid: this.prescriptionid,
            })
            .then(async (response) => {
              this.$store
                .dispatch("prescription/prescribePrescription", {
                  patientid: this.patientid,
                  prescriptionid: this.prescriptionid,
                })
                .then(async (data) => {
                  let record = this.tableItem.find(
                    (x) => x.id === this.prescriptionid
                  );
                  if (record) {
                    record.prescriptionstatus = data.prescriptionstatus;
                    record.actionOrder =
                      data.prescriptionstatus === "FAILED"
                        ? ["Retry", "Cancel"]
                        : ["Cancel"];
                  }

                  if (data.refillprescriptionid != undefined) {
                    this.$store
                      .dispatch("prescription/refreshPrescriptionOrder", {
                        patientid: data.patientid,
                        prescriptionid: data.refillprescriptionid,
                      })
                      .then(async (datarefill) => {
                        let recordrefill = this.tableItem.find(
                          (x) => x.id === data.refillprescriptionid
                        );
                        if (record) {
                          recordrefill.prescriptionstatus =
                            datarefill.prescriptionstatus;
                          recordrefill.actionOrder = [];
                        }
                      });
                  }
                  this.isProcessing = false;
                  this.$bvModal.hide("updatePrescribe");
                  this.onUpdatePrescribeModalHide();
                  this.$emit("poll-prescriptions", { type: "poll" });
                });
            })
            .catch((error) => {
              this.$bvModal.hide("updatePrescribe");
              this.isProcessing = false;
              this.alertMessage("warning", error.message, 15);
            });

          break;
        case "deletePrescribe":
          this.isProcessing = true;
          this.$store
            .dispatch("prescription/deletePrescription", {
              patientid: this.patientid,
              prescriptionid: this.prescriptionid,
            })
            .then((data) => {
              this.tableItem.splice(
                this.tableItem.findIndex(
                  (item) => item.prescriptionid === this.prescriptionid
                ),
                1
              );

              this.isProcessing = false;
              this.$bvModal.hide("deletePrescribe");
              this.onDeletePrescribeModalHide();
              this.$emit("poll-prescriptions");
            })
            .catch((e) => {
              this.$bvModal.hide("deletePrescribe");
              this.isProcessing = false;
              console.error(e);
            });
          break;

        case "stopPrescribe":
          this.isProcessing = true;
          this.$store
            .dispatch("prescription/stopPrescription", {
              patientid: this.patientid,
              prescriptionid: this.prescriptionid,
            })
            .then((data) => {
              let record = this.tableItem.find(
                (x) => x.prescriptionid === this.prescriptionid
              );
              if (record) {
                record.prescriptionstatus = data.prescriptionstatus;
                record.actionOrder = [];
              }
              this.isProcessing = false;
              this.$bvModal.hide("stopPrescribe");
            })
            .catch((e) => {
              this.$bvModal.hide("stopPrescribe");
              this.isProcessing = false;
              console.error(e);
            });
          break;

        case "continuePrescribe":
          this.isProcessing = true;
          this.$store
            .dispatch("prescription/continuePrescription", {
              patientid: this.patientid,
              prescriptionid: this.prescriptionid,
            })
            .then((data) => {
              let record = this.tableItem.find(
                (x) => x.prescriptionid === this.prescriptionid
              );

              if (record) {
                record.prescriptionstatus = data.prescriptionstatus;
                record.actionOrder = [];
              }
              this.isProcessing = false;
              this.$bvModal.hide("continuePrescribe");
            })
            .catch((e) => {
              this.$bvModal.hide("continuePrescribe");
              this.isProcessing = false;
              console.error(e);
            });
          break;
        case "cancelPrescribe":
          this.isProcessing = true;
          this.$store
            .dispatch("prescription/cancelPrescriptionOrder", {
              prescriptionid: this.prescriptionid,
            })
            .then((data) => {
              let record = this.tableItem.find(
                (x) => x.prescriptionid === this.prescriptionid
              );
              if (record) {
                record.prescriptionstatus = data.prescriptionstatus;
                record.actionOrder = [];
              }
              this.isProcessing = false;
              this.$bvModal.hide("cancelPrescribe");
              this.onCancelPrescribeModalHide();
              this.$emit("poll-prescriptions");
            })
            .catch((e) => {
              this.$bvModal.hide("cancelPrescribe");
              this.isProcessing = false;
              console.error(e);
            });
          break;

        case "retryPrescribe":
          try {
            this.isRetrying = true;
            const data = await this.$store.dispatch(
              "prescription/retryPrescription",
              {
                prescriptionid: this.prescriptionid,
              }
            );
            let record = this.tableItem.find(
              (x) => x.prescriptionid === this.prescriptionid
            );
            if (record) {
              record.prescriptionstatus = data.prescriptionstatus;
              record.actionOrder = [];
            }
            this.$emit("poll-prescriptions", { type: "poll" });
            this.$bvModal.hide("retryPrescribe");
            this.isRetrying = false;
          } catch (error) {
            this.$bvModal.hide("retryPrescribe");
            this.alertMessage("warning", error.message, 15);
            this.isRetrying = false;
          }
          break;

        case "sendPrescriptionConfirmation":
          this.$store
            .dispatch("prescription/sendPrescriptionOrderConfirmation", {
              patientid: this.patientid,
              prescriptionid: this.prescriptionid,
            })
            .then((data) => {
              this.tableItem.find(
                (x) => x.prescriptionid === this.prescriptionid
              ).prescriptionstatus = data.prescriptionstatus;
              this.prescription.prescriptionstatus = data.prescriptionstatus;
            });
          this.$root.$emit("bv::hide::modal", id);
          break;

        case "clearPatient":
          if (this.ignoreType === "Message, Ignore") {
            this.$store.dispatch("message/ignoreMessage", {
              conversationid: this.prescription.conversationid,
            });
          } else if (this.ignoreType === "RX Request, Ignore") {
            this.$store.dispatch("upsell/denyUpsell", {
              upsellid: this.prescription.patientupsellid,
            });
          } else if (this.ignoreType === "RX Refill, Ignore") {
            let ignorePrescriptionPromises =
              this.prescription.prescriptionorderid.map((id) => {
                return this.$store.dispatch("prescription/ignorePrescription", {
                  patientid: this.patientid,
                  patientfollowupid: id,
                });
              });
            Promise.all(ignorePrescriptionPromises)
              .then((data) => {
                this.$emit("row-remove", {
                  patientid: this.patientid,
                  date: this.prescription.date,
                });
              })
              .catch((e) => console.error(e));
          } else if (this.ignoreType === "Follow-Up, Ignore") {
            let ignoreFollowupPromises =
              this.prescription.patientfollowupid.map((id) => {
                return this.$store.dispatch("prescription/ignoreFollowup", {
                  patientid: this.patientid,
                  patientfollowupid: id,
                });
              });
            Promise.all(ignoreFollowupPromises)
              .then((data) => {
                this.$emit("row-remove", {
                  patientid: this.patientid,
                  date: this.prescription.date,
                });
              })
              .catch((e) => console.error(e));
          }
          this.$bvModal.hide("clearPatient");
          break;
      }
    },
    onRowSelected(rows) {
      if (Object.keys(rows).length > 0) {
        this.rowSelect = rows;
        this.$emit("selectedPrescription", this.rowSelect[0].data);
      } else {
        this.$emit("selectedPrescription", {});
      }
    },
    checkAll() {
      // see FIGURE: 3
      this.selected = [];

      let itemsPerPage = this.tableItem;

      // if pagination is true
      if (this.hasPagination)
        itemsPerPage = this.tableItem.slice(
          this.indexNumber - 1,
          this.indexLast
        );

      if (!this.selectAll)
        itemsPerPage.forEach((item) => {
          this.selected.push(item.value);
        });
    },
    pageChange(currentPage) {
      // see FIGURE: 1
      // paginate list view
      this.indexNumber = (currentPage - 1) * this.itemPerPage + 1;
      this.indexLast = this.indexNumber - 1 + this.itemPerPage;

      // if pagination is true
      if (this.hasPagination)
        this.selectAll =
          this.selected.length == this.indexLast
            ? true
            : this.indexLast > this.rows
            ? false
            : false;

      if (this.indexLast > this.rows) this.indexLast = this.rows;
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      if (this.filter) {
        this.rows = filteredItems.length;
        // this.indexNumber = filteredItems.length
        // this.indexLast = filteredItems
        // this.currentPage = 1
        return;
      } else {
        this.rows = this.tableItem.length;
        this.indexNumber = (this.currentPage - 1) * this.itemPerPage + 1;
        this.indexLast = this.indexNumber - 1 + this.itemPerPage;
        if (this.indexLast > this.rows) this.indexLast = this.rows;
        return;
      }
    },

    gridPaginate(items) {
      // paginate grid view
      let page = this.currentPage,
        perPage = this.itemPerPage,
        from = page * perPage - perPage,
        to = page * perPage;

      return items.slice(from, to);
    },
    onScroll(e) {
      this.$emit("scroll", e);
    },

    async getPatientInfo(patientid) {
      return PatientService.getPatient(patientid)
        .then(async ({ data }) => {
          // // recentlyViewPatients.push(data);
          // let checkExists = recentlyViewPatients.find(x => x.id === data.id)
          // console.log('checkExists', checkExists)
          // if (!checkExists) {
          //   recentlyViewPatients.push({ id: data.id, firstname: data.firstname, lastname: data.lastname, profileUrl: data.profileUrl });
          // }
          // else {
          //   let existindex = recentlyViewPatients.indexOf(checkExists)
          //   recentlyViewPatients.splice(existindex, 1);
          //   recentlyViewPatients.push({ id: data.id, firstname: data.firstname, lastname: data.lastname, profileUrl: data.profileUrl });
          // }
          // localStorage.setItem('recentlyViewedPatients', JSON.stringify(recentlyViewPatients));
          // data.questions = await dispatch("getHealthQuestions", patientId);
          if (data.allergy && data.allergy.length > 0) {
            let result = data.allergy.map((item) => {
              return item.allergy;
            });
            this.allergies = result.join(",");
          } else {
            this.allergies = "None stated";
          }
          // console.log('PATIENT_INFO', data);
          return data;
        })
        .catch((error) => console.log(error));
    },
    async action(action, patientid, prescriptionid, item) {
      localStorage.setItem("patientId", patientid);
      this.dosepotPrescriptions = {};

      switch (action) {
        case "RX Refill":
          break;
        // case "Refill":     /////// <<<< SAME WITH PRESCRIBE

        //   this.allergies = "";
        //   this.patientid = patientid;
        //   this.getPatientInfo(patientid);

        //   this.prescriptionid = prescriptionid;
        //   this.prescription = item;
        //   this.$store
        //     .dispatch("prescription/generateDospatURL", this.patientid)
        //     .then(async (data) => {
        //       this.loading = true;
        //       this.dospatURL = data;
        //       this.dosepotPrescriptions = await this.$store.dispatch(
        //         "prescription/getDosepotPrescription",
        //         {
        //           patientid: this.patientid,
        //           prescriptionid: this.prescriptionid,
        //         }
        //       );
        //     })
        //     .catch(error => {
        //       this.alertMessage("warning", error.message, 15);
        //     })
        //     .finally(() => (this.loading = false));

        //   break;
        case "Accept":
          await PatientService.acceptPatient(patientid);
          this.$store.dispatch("dashboard/getDashboardData").then((data) => {
            this.$router.push(`/verification/${patientid}`);
          });
          break;
        case "Update":
          localStorage.setItem("prescriptionOrderId", prescriptionid);
          break;
        case "Refill":
          let record = this.tableItem.find((x) => x.id === prescriptionid);
          if (record) {
            // if (!record.followupanswered) {
            //   this.$root.$emit("bv::show::modal", "warnFollowUp");
            // }
          }
          localStorage.setItem("prescriptionOrderId", prescriptionid);
          localStorage.setItem("isRefill", true);
          break;
        case "Send Confirmation":
          this.patientid = patientid;
          this.prescriptionid = prescriptionid;
          break;
        case "Cancel":
          this.patientid = patientid;
          this.prescriptionid = prescriptionid;
          break;
        case "Continue":
          this.patientid = patientid;
          this.prescriptionid = prescriptionid;
          break;
        case "Stop":
          this.patientid = patientid;
          this.prescriptionid = prescriptionid;
          break;
        case "Prescribe":
          this.allergies = "";
          this.patientid = patientid;
          this.getPatientInfo(patientid);

          this.prescriptionid = prescriptionid;
          this.prescription = item;
          this.$store
            .dispatch("prescription/generateDospatURL", this.patientid)
            .then(async (data) => {
              this.loading = true;
              this.dospatURL = data;
              this.dosepotPrescriptions = await this.$store.dispatch(
                "prescription/getDosepotPrescription",
                {
                  patientid: this.patientid,
                  prescriptionid: this.prescriptionid,
                }
              );
            })
            .catch((error) => {
              this.alertMessage("warning", error.message, 15);
            })
            .finally(() => (this.loading = false));
          break;
        case "Delete":
          this.patientid = patientid;
          this.prescriptionid = prescriptionid;
          break;
        case "Retry":
          this.patientid = patientid;
          this.prescriptionid = prescriptionid;
          break;
        case "Resume":
          this.patientid = patientid;
          this.prescriptionid = prescriptionid;
          break;
        case "Message, Ignore":
          this.patientid = patientid;
          this.prescription = item;
          this.ignoreType = action;
        case "RX Request, Ignore":
          this.patientid = patientid;
          this.prescription = item;
          this.ignoreType = action;
        case "RX Refill, Ignore":
          this.patientid = patientid;
          this.prescription = item;
          this.ignoreType = action;
        case "Follow-Up, Ignore":
          this.patientid = patientid;
          this.prescription = item;
          this.ignoreType = action;
      }
      this.renderComponent = true;
    },
    parseDate(date, type, delimiter = "-") {
      return format(
        type === "unix" ? fromUnixTime(date) : new Date(date),
        `MM${delimiter}dd${delimiter}yyyy`
      );
    },
    onUpdatePrescribeModalHide() {
      this.$store.dispatch("prescription/togglePrescriptionModal", false);
      this.$store.dispatch("prescription/setSelectedPrescription", {});
    },
    onCancelPrescribeModalHide() {
      this.$store.dispatch("prescription/toggleCancelPrescriptionModal", false);
      this.$store.dispatch("prescription/setSelectedPrescription", {});
    },
    onRetryPrescribeModalHide() {
      this.$store.dispatch("prescription/toggleRetryPrescriptionModal", false);
      this.$store.dispatch("prescription/setSelectedPrescription", {});
    },
    onDeletePrescribeModalHide() {
      this.$store.dispatch("prescription/toggleDeletePrescriptionModal", false);
      this.$store.dispatch("prescription/setSelectedPrescription", {});
    },
    dispatchAction(actionType, selectedPrescription) {
      this.action(
        actionType,
        selectedPrescription.patientid
          ? selectedPrescription.patientid
          : selectedPrescription.patientid.data.patientid,
        selectedPrescription.prescriptionid,
        selectedPrescription
      );
    },
    getRefillString(med) {
      if (med.refills) {
        if (
          ["SCHEDULED", "WAITING", "PROCESSING"].includes(
            med.prescriptionstatus
          )
        ) {
          return `Refills: ${med.refillinput}`;
        }
        const refills = med.refills - 1 - (med.paidrefills - 1);
        if (med.paidrefills === 0 && med.paymentstatus === "FAILED") {
          return `${med.refills - med.paidrefills - 1} out of ${
            med.refills - 1
          } refills`;
        }

        if (med.refills && med.paidrefills) {
          return `${refills} out of ${med.refills - 1} refills`;
        } else {
          return "One time purchase";
        }
      } else {
        return "No refills";
      }
    },
    getPrescriptionDetailsDate(timestamp) {
      if (!timestamp) {
        return "-";
      } else {
        if (typeof timestamp == "String") {
          timestamp = Number(timestamp);
        }
        return new moment(timestamp * 1000).format("MMMM D, YYYY");
      }
    },
  },
};
</script>

<style scoped>
/deep/#updatePrescribe .modal-dialog {
  min-width: 100% !important;
  min-height: 100% !important;
  margin: 0 !important;
}

/deep/#updatePrescribe .modal-body {
  padding: 0;
}

/deep/.pagination {
  margin-bottom: 0;
}

/deep/.page-item.active {
  display: none;
}

/deep/.page-link[role="menuitemradio"] {
  display: none;
}

/deep/.page-item.active .page {
  display: none;
}

/deep/.table thead th,
/deep/.table.b-table > thead > tr > .table-b-table-default,
.table.b-table > tbody > tr > .table-b-table-default,
.table.b-table > tfoot > tr > .table-b-table-default {
  background-color: #fafbfc;
}

/* WIN-14944 */
/* 
Headers with sorting have position: relative 
for some reason, leaving the header behind. 
Not sure why this happens only on staging.
*/
/deep/.table.b-table > thead > tr > .position-relative {
  position: sticky !important;
}

/*
Causes the header to be underneath the row details.
Not sure why this is here, but I'm commenting it out for now
since it seems to fix the issue.
*/
/deep/.b-table-sticky-header > .table.b-table > thead > tr > th {
  z-index: 0 !important;
}

/deep/.table.b-table > thead > tr > .table-b-table-default {
  color: #828282;
  text-transform: capitalize;
  font-weight: normal;
  letter-spacing: 0.75px;
}

/deep/.table thead th:focus {
  /* outline: 0; */
  user-select: none;
}
</style>

<style lang="scss">
.table.b-table > tbody > .table-active,
.table.b-table > tbody > .table-active > th,
.table.b-table > tbody > .table-active > td {
  background-color: $primary;
  color: $white;
}

.table thead th {
  font-size: 12px;
}
</style>

<style lang="scss" scoped>
.dashboard-table .b-table-sticky-header {
  @include media-breakpoint-down(sm) {
    max-height: calc(100vh - 20vh) !important;
  }
}

// .b-table-sticky-header {
//   @include media-breakpoint-down(sm) {
//     max-height: (100vh - 20vh);
//   }
// }

.dosepot {
  background-color: #124466;
  color: #fff;
  margin-left: -1px;
  font-size: 16px;

  .dosepot-title {
    color: #c6c6c6;
    font-size: 14px;
    font-weight: normal !important;
  }

  .card-footer {
    border: 0;
  }
}

.checkbox {
  display: block;
  margin-bottom: 0;
}

// .card-table {
//   @include media-breakpoint-up(xxl) {
//     max-height: (100vh - 50vh) !important
//   }
// }

.card.table-card {
  border: 0;
  background-color: rgb(250 251 252);
}

.table thead th {
  border: 0;
}

.table tbody td {
  border-bottom: 0;
  border-top: 1px solid rgba(26, 106, 114, 0.2);
}

.card-header {
  background-color: transparent;
  border-bottom: 1.5px solid $primary;
  height: initial;
  min-height: 60px;
}

.grid {
  background-color: $white;

  .card-body {
    padding: 10px;
  }
}

.dot {
  height: 10px;
  width: 10px;
  background-color: $secondary;
  border-radius: 50%;
  display: inline-block;
}

.badge-purple {
  background-color: #a783ff;
}

.text-small {
  font-size: 0.8rem;
}
</style>
